import { ApiContext } from 'apicontext';
import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GENE_EXPRESSION_QUERY, GENE_ID_QUREY } from './queries';
import { GeneExpressionQueryResponse, GeneIdQueryResponse } from './types';

export function useGeneExpressionData(assembly: string, gene_name: string, assay_term_name: string) {
    const client = useContext(ApiContext).client;
    const { data, loading } = useQuery<GeneIdQueryResponse>(GENE_ID_QUREY, {
        client,
        variables: {
            gene_name,
            assembly,
        },
    });
    return useQuery<GeneExpressionQueryResponse>(GENE_EXPRESSION_QUERY, {
        client,
        variables: {
            gene_id: data?.gene[0]?.id.split('.')[0] || '',
            assembly,
            assay_term_name
            
        },
        skip: loading || !data,
    });
}
